.container {
  width: 100%;
  padding: 6px 4.6vw;
  background: white;
}

.dropdownItem,
.navItem {
  margin: 0;
  color: #566985;
  text-align: left;
  text-transform: uppercase;
  font-family: Inter-SemiBold;
  font-size: 16px;
  line-height: 150%;
  font-weight: 600;
  transition: 0.2s;
  letter-spacing: 0.2px;
  cursor: pointer;
  display: table;
  margin: 0px 20px;
}

.newDropdownItem {
  margin: 0;
  color: #2d3958;
  text-align: left;
  text-transform: none;
  font-family: Inter-Medium;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  transition: 0.2s;
  cursor: pointer;
  display: table;
  margin: 28px 20px;
}

.dropdownItem {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.newDropdownItem:hover,
.dropdownItem:hover,
.navItem:hover {
  color: #6d69d3 !important;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
}

@media (max-width: 1150px) {
  .dropdownItem,
  .navItem {
    margin-top: 20px;
    margin: 30px 20px;
  }
}

@media (max-width: 850px) {
  .buttonsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 1024px) {
  .zoneItem {
    color: 'blue' !important;
  }
}
